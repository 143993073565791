<template>
    <div class="app-container">
        <div class="filter-container">
            <div class="label">Active users with no used funds</div>
            <el-select v-model="period" class="range-selector">
                <el-option :value="3" label="for the last 3 months" />
                <el-option :value="6" label="for the last 6 months" />
                <el-option :value="12" label="for the last 12 months" />
                <el-option :value="0" label="ever" />
            </el-select>
        </div>

        <user-list
            v-loading="loading.state"
            :users="filteredUsers"
            :element-loading-text="loading.message"
            :show_last_claim="true"
            :css="{height: 'calc(100vh - 200px)'}"
            :scroll="true"
            :new_window="true"
            @new-claim="newClaim"
        />

        <claim-modal
            :categories="user_categories"
            :data="user_plan"
            :user="user"
        />
    </div>
</template>

<script>
import UserList from '@/views/components/UserList';
import ClaimModal from '@/views/components/ClaimModal';
import moment from 'moment';
import _ from 'lodash';

export default {
    name: 'expiry',
    components: {
        UserList,
        ClaimModal,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            users: [],
            loading: {
                state: false,
                message: null,
            },
            loadingMessage: null,
            search: '',
            debouncedSearch: '',
            period: 3, // how many months count as unused funds
            sort: {
                column: 'name',
                dir: 'asc',
            },
            // new claims
            user_categories: [],
            user_plan: [],
            user: {},
            raw_categories: [],
        };
    },

    computed: {
        filteredUsers() {
            if (this.loading.state === true) {
                return [];
            }
            // filter out users who have made a claim within the requested period
            let targetDate = moment();
            if (this.period > 0) {
                targetDate = moment(targetDate).subtract(this.period, 'months');
            }
            return this.users
                .filter((u) => {
                    if (u.last_claim && this.period > 0) {
                        return moment(u.last_claim).isBefore(targetDate);
                    }
                    return false;
                })
                .sort((a, b) => {
                    if (moment(a.last_claim).isBefore(b.last_claim)) return 1;
                    if (moment(a.last_claim).isAfter(b.last_claim)) return -1;
                    return 0;
                });
        },
    },

    async mounted() {
        this.getUnusedFunds();

        // categories for new claim modal
        this.$bind(
            'raw_categories',
            this.$fire.collection('categories').orderBy('order'),
            {maxRefDepth: 0}
        );
    },
    methods: {
        async getUnusedFunds() {
            this.loading.state = true;
            this.loading.message = 'Getting users...';

            // get all users
            let query = this.$fire.collection('users');
            query = query.where('status', '==', 'active');

            try {
                let lastClaims = {};
                let claimCalls = [];
                const snapshot = await query.get();
                if (snapshot.size > 0) {
                    await this.$bind('users', query, {maxRefDepth: 0});
                    let count = 0;
                    this.loading.message = 'Finding users with unused funds...';
                    for (const user of this.users) {
                        // get most recent claim for user
                        claimCalls.push(
                            this.$fire
                                .collection('claims')
                                .where(
                                    'user',
                                    '==',
                                    this.$fire.doc(`users/${user.id}`)
                                )
                                .orderBy('date', 'desc')
                                .limit(1)
                                .get()
                                .then((snapshot) => {
                                    if (!snapshot.empty) {
                                        const lastClaim =
                                            snapshot.docs[0].data();
                                        user.last_claim =
                                            this.$options.filters.timestamp2Date(
                                                lastClaim.date.seconds
                                            );
                                        count++;
                                        this.loading.message = `Finding users with unused funds... ${(
                                            (count / this.users.length) *
                                            100
                                        ).toFixed()}%`;
                                    }
                                })
                        );
                    }
                }
                await Promise.all(claimCalls);
                this.update++;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading.state = false;
            }
        },

        newClaim(user, activePlan) {
            this.user = user;
            this.user_plan = activePlan.data.map((item) => {
                return {category: item.category.path, budget: item.budget.path};
            });

            // get assigned categories for user
            const plan_categories = activePlan.data.map((item) => {
                return item.category.path;
            });
            this.user_categories = this.raw_categories
                .filter((raw_cat) => {
                    return plan_categories.includes(`categories/${raw_cat.id}`);
                })
                .sort((a, b) => {
                    return a.order - b.order;
                });
            this.$bus.$emit('claim-modal', {user});
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    div {
        margin-right: 10px;
    }

    .label {
        font-size: 14px;
    }

    .new-user {
        float: right;
    }
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}
</style>
