var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v("Active users with no used funds"),
          ]),
          _c(
            "el-select",
            {
              staticClass: "range-selector",
              model: {
                value: _vm.period,
                callback: function ($$v) {
                  _vm.period = $$v
                },
                expression: "period",
              },
            },
            [
              _c("el-option", {
                attrs: { value: 3, label: "for the last 3 months" },
              }),
              _c("el-option", {
                attrs: { value: 6, label: "for the last 6 months" },
              }),
              _c("el-option", {
                attrs: { value: 12, label: "for the last 12 months" },
              }),
              _c("el-option", { attrs: { value: 0, label: "ever" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("user-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading.state,
            expression: "loading.state",
          },
        ],
        attrs: {
          users: _vm.filteredUsers,
          "element-loading-text": _vm.loading.message,
          show_last_claim: true,
          css: { height: "calc(100vh - 200px)" },
          scroll: true,
          new_window: true,
        },
        on: { "new-claim": _vm.newClaim },
      }),
      _c("claim-modal", {
        attrs: {
          categories: _vm.user_categories,
          data: _vm.user_plan,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }